// .financialcontainer {
//   height: 10rem;
//   width: 10rem;
//   background-color: grey;
// }

.financialreportpage {
  display: flex;
  justify-content: center;
  //   justify-content: center;
  //   align-items: center;
}

.financialcontainer {
  background: #fff;
  border-radius: 2px;
  height: 250px;
  margin: 1rem;
  width: 300px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem 0rem;
}
