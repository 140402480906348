@tailwind base;
@tailwind components;
@tailwind utilities;
@import "base";
@import "global";
@import "mixins";
@import "typography";
@import "variables";

// styles/components
@import "components/Header.scss";
@import "components/ServiceCard.scss";
@import "components/Footer.scss";
@import "components/SecondHeader.scss";
@import "components/Loader.scss";
@import "components/Sidebar.scss";

// styles/screens
@import "screens/HomeScreen.scss";
@import "screens/ReportScreen.scss";
@import "screens/Login.scss";
@import "screens/ForgotScreen.scss";
@import "screens/resetPasswordScreen.scss";
@import "screens/FinancialReport.scss";
@import "screens/CurrentPlan.scss";
@import "screens/Recharge.scss";
