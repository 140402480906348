.cardcontainer {
  display: grid;
  grid-column: 1/15;
  grid-row: 1/5;
  grid-template-columns: repeat(15, 1fr);
  align-items: center;
  height: 100%;
}

.leftcardcontainer {
  display: grid;
  grid-column: 1/8;
  grid-row: 1/5;
  background-color: #41309b;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(12, 1fr);
  @media only screen and (max-width: $bp-small) {
    grid-column: 1/16;
  }

  &_logincard {
    grid-column: 3/11;
    background-color: white;
    padding: 4rem 1rem;
    border-radius: 2rem;
    box-shadow: 0px 8px 39px 0px rgba(255, 255, 255, 0.5);
    grid-row: 3/10;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    height: 100%;
    align-items: center;
    justify-content: center;
    &__form {
      grid-column: 3/11;
    }
    @media only screen and (max-width: $bp-small) {
      z-index: 500;
      width: 100%;
      grid-column: 2/12;
    }
  }
  &__logo {
    grid-column: 4/10;
    grid-row: 1/2;
    width: 100%;
    @media only screen and (max-width: $bp-small) {
    }
  }
  &__input1 {
    grid-column: 3/11;
    grid-row: 2/3;
    @media only screen and (max-width: $bp-small) {
      grid-column: 2/12;
    }
  }
  &__input2 {
    grid-column: 3/11;
    grid-row: 3/4;
    margin-top: 1.2rem;
    @media only screen and (max-width: $bp-small) {
      grid-column: 2/12;
    }
  }
  &__link {
    grid-column: 5/11;
    grid-row: 4/5;
    justify-self: end;
    @media only screen and (max-width: $bp-small) {
      grid-column: 4/12;
    }
  }
  &__link:hover {
    text-decoration: underline;
    color: gray;
  }
  &__button {
    width: 100%;
    color: white;
    background-color: #41309b;
    grid-column: 3/11;
    grid-row: 5/6;
    padding: 2rem;
    font-size: 1.2rem;

    @media only screen and (max-width: $bp-small) {
      grid-column: 2/12;
      border-radius: 1rem;
    }
  }
  &__button:hover {
    background-color: white;
    color: #41309b;
    border: 1px solid #41309b;
  }
}

.rightcardcontainer {
  display: grid;
  grid-column: 8/16;
  grid-row: 1/4;
  height: 100%;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(4, 1fr);
  @media only screen and (max-width: $bp-small) {
    grid-column: 1/16;
  }
  &_div1 {
    grid-column: 3/6;
    grid-row: 1/3;
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-self: center;
    margin-top: 4rem;

    @media only screen and (max-width: $bp-small) {
    }
  }
  &_div2 {
    grid-column: 2/7;
    grid-row: 3/5;
    @media only screen and (max-width: $bp-medium) {
      grid-column: 1/8;
    }
    @media only screen and (max-width: $bp-small) {
      grid-column: 2/7;
      grid-row: 1/2;
      g rect {
        fill: rgba(255, 255, 255, 0.2);
      }
    }
  }
  &__span1 {
    font-size: 4.5rem;
    color: black;
    font-weight: 600;
    text-shadow: 0px 7px 21px rgba(124, 101, 242, 0.6);
    @media only screen and (max-width: $bp-small) {
      display: none;
    }
  }
  &__span2 {
    font-size: 4.5rem;
    font-weight: 600;
    color: black;
    text-shadow: 0px 7px 21px rgba(124, 101, 242, 0.6);
    @media only screen and (max-width: $bp-small) {
      display: none;
    }
  }
  &__span3 {
    font-size: 4.5rem;
    font-weight: bolder;
    color: #41309b;
    text-shadow: 0px 7px 21px rgba(124, 101, 242, 0.6);
    @media only screen and (max-width: $bp-small) {
      display: none;
    }
  }
}
