.leftcardcontainer {
  &_changepasscard {
    grid-column: 3/11;
    background-color: white;
    padding: 4rem 1rem;
    border-radius: 2rem;
    box-shadow: 0px 8px 39px 0px rgba(255, 255, 255, 0.5);
    grid-row: 3/11;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    height: 100%;
    align-items: center;
    justify-content: center;
    &__form {
      grid-column: 3/11;
    }
    @media only screen and (max-width: $bp-small) {
      z-index: 500;
      width: 100%;
      grid-column: 2/12;
    }
  }
  &__changepassinput1 {
    grid-column: 3/11;
    grid-row: 2/3;
    @media only screen and (max-width: $bp-small) {
      grid-column: 2/12;
    }
  }
  &__changepassinput2 {
    grid-column: 3/11;
    grid-row: 3/4;
    @media only screen and (max-width: $bp-small) {
      grid-column: 2/12;
    }
  }
  &__changepassbutton {
    width: 100%;
    color: white;
    background-color: #41309b;
    grid-column: 3/11;
    grid-row: 4/5;
    padding: 2rem;
    font-size: 1.2rem;

    @media only screen and (max-width: $bp-small) {
      grid-column: 2/12;
      border-radius: 1rem;
    }
  }
  &__changepassdivbacktologin {
    grid-column: 3/11;
    grid-row: 5/6;
    justify-self: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0rem 0.5rem;
  }
  &__changepassbacktologin {
    width: 100%;
    color: #41309b;
    grid-column: 3/11;
    grid-row: 5/6;
    font-size: 1.2rem;
  }
  &__changepassbacktologin:hover {
    font-weight: bolder;
    color: #41309b;
  }
}
