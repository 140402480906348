.rechargepagecontainer {
  background: #fDfDfD;
  border-radius: 2px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  // width:90%;
  margin:0 auto;
  gap: 1rem 0rem;
}
