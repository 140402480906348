.search {
  flex: 0 0 40%;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  @media only screen and (max-width: $bp-smallest) {
    display: none;
    order: 1;
    flex: 0 0 100%;
    background-color: var(--color-grey-light-2);
  }
  &__input {
    font-family: inherit;
    font-size: inherit;
    background-color: var(--color-grey-light-2);
    border: none;
    padding: 1rem 2rem;
    border-radius: 100px;
    width: 90%;
    transition: all 0.2s;
    margin-right: -3.5rem;

    &:focus {
      outline: none;
      width: 100%;
      background-color: var(--color-grey-light-3);
    }

    &::-webkit-input-placeholder {
      font-weight: 100;
      color: var(--color-grey-light-4);
    }
  }

  &__input:focus + &__button {
    background-color: var(--color-grey-light-3);
  }

  &__button {
    border: none;
    background-color: var(--color-grey-light-2);
    color: var(--color-primary);

    &:focus {
      outline: none;
    }

    &:active {
      transform: translateY(2px);
    }
  }
  &__icon {
    height: 2rem;
    width: 2rem;
    fill: var(--color-grey-dark-3);
  }
}

.header {
  height: 7rem;
  font-size: 1.4rem;
  background-color: #fff;
  border-bottom: var(--line);
  padding: 0rem 4rem;
  display: grid;
  grid-column: 1/15;
  grid-row: 1/3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  &__icon {
    color: white;
    font-size: 1.2rem;
    padding: 5px 10px;
    border: 1px solid var(--color-primary);
    border-radius: 10px;
    background-color: var(--color-primary);
    cursor: pointer;
  }

  @media only screen and (max-width: $bp-smallest) {
    flex-wrap: wrap;
    height: 11rem;
    align-content: space-around;
  }
}
.header-afterlogin {
  height: 7rem;
  font-size: 1.4rem;
  background-color: #fff;
  border-bottom: var(--line);
  padding: 0rem 4rem;
  display: grid;
  grid-column: 1/15;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  &__icon {
    color: white;
    font-size: 1.2rem;
    padding: 5px 10px;
    border: 1px solid var(--color-primary);
    border-radius: 10px;
    background-color: var(--color-primary);
  }

  @media only screen and (max-width: $bp-smallest) {
    padding: 0.5rem 2rem;
    width: 100%;
    flex-wrap: wrap;
    height: auto;
    align-content: space-between;
  }
}
.transition-div {
  transition: all 0.5s;
  height: 0;
  overflow: hidden;
}

.transition-div.open {
  overflow: hidden;
  height: auto; /* Adjust based on the content height */
}
.rightnav {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0rem 2rem;
  @media only screen and (max-width: $bp-smallest) {
    display: none;
  }
  &-1 {
    display: flex;
    align-items: center;
    justify-content: center;

    :hover {
      color: var(--color-primary);
      cursor: pointer;
    }
  }
  &-2 {
    width: 8.4rem;
    height: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1.7332rem;
    &__para {
      color: white;
    }
  }
}

.rightnav-menu-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  @media only screen and (min-width: $bp-smallest) {
    display: none;
  }
  &_form {
    width: 80%;
    background-color: var(--color-grey-light-2);
  }

  &__input {
    font-family: inherit;
    font-size: inherit;
    background-color: var(--color-grey-light-2);
    border: none;
    padding: 1rem 2rem;
    border-radius: 100px;
    width: 70%;
    transition: all 0.2s;
    margin-right: -3.5rem;
    &:focus {
      outline: none;
      width: 100%;
    }
  }
  &__button {
    border: none;
    background-color: var(--color-grey-light-2);
    color: var(--color-primary);

    &:focus {
      outline: none;
    }

    &:active {
      transform: translateY(2px);
    }
  }
}

.dropdown-container {
  position: relative;
  font-size: 1.2rem;
  display: inline-block;
  color: white;
  padding: 1px 6px;
  border: 1px solid var(--color-primary);
  border-radius: 8px;
  background-color: var(--color-primary);
}

.dropdown-header {
  cursor: pointer;

  padding: 6px;
  // border: 1px solid #ccc;
  border-radius: 4px;
}

// .dropdown-header:hover {
//   background-color: #f0f0f0;
// }

.dropdown-content {
  background-color: #fff;
  border-top: none;
  box-shadow: 0 2rem 4rem rgba(0, 0, 0, 0.5);
  display: none;
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 50000;
}

.dropdown-container:hover .dropdown-content {
  display: block;
}

ul {
  list-style: none;
  padding: 0;
  width: 12rem;
}

li {
  border-bottom: 1px solid #ccc;
  padding: 1rem;
  transition: background-color 0.2s;
}

li:last-child {
  border-bottom: none;
}

// li:hover {
// background-color: #f0f0f0;
// }

a {
  text-decoration: none;
  color: #333;
}
a:hover {
  color: var(--color-primary);
}

button {
  // background-color: var(--color-primary);
  // color: #fff;
  // border: none;
  border-radius: 4px;
  // padding: 5px 10px;
  cursor: pointer;
}

button:hover {
  // background-color: var(--color-primary);
}

.hamburger-menu {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.line {
  width: 15px;
  height: 2px;
  background-color: #333;
  margin: 2px 0;
  transition: 0.4s;
}

/* Animation for the hamburger to cross icon */
.hamburger-menu.active .line:nth-child(1) {
  transform: rotate(-45deg) translate(-3px, 3px);
}

.hamburger-menu.active .line:nth-child(2) {
  opacity: 0;
}

.hamburger-menu.active .line:nth-child(3) {
  transform: rotate(45deg) translate(-5px, -6px);
}
