// COLOR VARIABLES
:root {
  --color-primary: #007aff;
  --color-primary-light: #21c576;
  --color-primary-dark: #ba265d;
  --color-grey-light-1: #faf9f9;
  --color-grey-light-2: #f4f2f2;
  --color-grey-light-3: #f0eeee;
  --color-grey-light-4: #ccc;
  --color-grey-dark-1: #333;
  --color-grey-dark-2: #777;
  --color-grey-dark-3: #999;

  --shadow-dark: 0 2rem 6rem rgba(0, 0, 0, 0.3);
  --shadow-light: 0 2rem 5rem rgba(0, 0, 0, 0.06);
  --line: 1px solid var(--color-grey-light-2);
}
// FONT VARIABLES
$font-primary: "Montserrat", sans-serif;

// RESPONSIVE BREAKPOINTS
$bp-largest: 75em; //1200px
$bp-large: 68.75em; //1100px
$bp-medium: 56.25em; //900px
$bp-small: 37.5em; //600px
$bp-smallest: 31.5em; //500px
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%; //10px/16px = 62.5% -> 1rem = 10px

  @media only screen and (max-width: $bp-smallest) {
    font-size: 70%;
  }
}

body {
  font-family: $font-primary;
  font-weight: 500;
  line-height: 1.4;
}

.text-primary {
  color: var(--color-primary);
}
.bg-primary {
  background-color: var(--color-primary);
}
.text-primary {
  color: var(--color-primary);
}
.text-whitish {
  color: white;
}
