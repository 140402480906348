/* Loader Container */
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* Loader Animation */
.loader {
  border: 4px solid rgba(0, 0, 0, 0.3);
  border-top: 4px solid #41309b;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
