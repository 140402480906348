.leftcontainer {
  padding: 0rem 2rem;
  display: grid;
  grid-column: 1/3;
  &fl {
    &-unorder {
      display: flex;
      flex-direction: column;
      gap: 1rem 0;

      &__off {
        padding: 0rem 2rem;
        display: grid;
        grid-column: auto;
      }

      &__list {
        list-style: none;
        display: flex;
        align-items: center;
        gap: 2rem 1rem;
      }
    }
  }
}

.middlecontainer {
  display: grid;
  grid-column: 3/14;
  // padding:0rem 2rem;
  grid-template-rows: min-content min-content max-content;
  gap: 3rem 0rem;
  @media only screen and (max-width: $bp-small) {
    grid-column: 3/13;
  }
}
.servicecards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(24rem, 1fr));
  gap: 1rem 1rem;
}
.rightcontainer {
  display: grid;
  grid-column: 14/15;
  padding: 0rem 2rem;
  gap: 1rem 0;
  color: white;
  display: flex;
  @media only screen and (max-width: $bp-small) {
    grid-column: 3/13;
  }
  &-card1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
    padding: 2rem 0rem;
    gap: 1rem 0;
    background-color: #6dbc4e;
    // width: 27rem;
    height: 27rem;
    h2 {
    }
  }
  &-card2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    border-radius: 1rem;
    padding: 2rem 0rem;
    background-color: #faa613;
    // width: 27rem;
    height: 10rem;
  }
}

.analytics-container {
  grid-column: 3 / 15;
  display: flex;
  justify-content: center;
  align-items: center;
 padding:1rem 0rem;
}

@media only screen and (max-width: $bp-small) {
  .analytics-container {
    grid-column: 3 / 13;
  }
}

