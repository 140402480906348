.containerpro {
  display: grid;
  // grid-template-rows: repeat(12, 1fr);
  grid-template-columns: repeat(14, minmax(min-content, 1fr));
  gap: 0rem 0rem;
  min-height: 100vh;
}
.containerpro-afterlogin {
  display: grid;
  grid-template-rows: auto 1fr auto;
  // grid-template-columns: repeat(14, minmax(min-content, 1fr));
  gap: 0rem 0rem;
  min-height: 100vh;
}
.midcontainer {
  display: grid;
  grid-column: 1/15;
  grid-row: 1/13;
  // min-height: 90vh;
  @media only screen and (max-width: $bp-small) {
  }
}
.midcontainer-afterlogin {
  display: grid;
  grid-column: 2/15;
  // grid-row: 3/11;
  // min-height: 90vh;
  @media only screen and (max-width: $bp-small) {
    // padding: 1.5rem;
    // grid-row: 2/11;
  }
}

.flex-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.l-gap {
  gap: 0.6rem;
}
