.btmfooter {
  grid-column: 1/15;
  grid-row: 11/13;
  margin-top: auto;
}

.btmfooter-afterlogin {
  grid-column: 1/15;
  margin-top: auto;
}
