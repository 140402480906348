.reportcontainer {
  grid-column: 1/15;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: min-content min-content max-content;

  gap: 1rem;
  @media only screen and (max-width: $bp-largest) {
  }
}

.reportcontainer-filters {
  grid-column: 3/9;
  grid-row: 2/3;
  padding: 0rem 1rem;
  @media only screen and (max-width: $bp-largest) {
    grid-column: 2/10;
    gap: 1rem 0rem;
  }
}

.report-table-container {
  border-radius: 20px;
  padding: 0 10px 10px 10px;
  display: grid;
  grid-column: 3/9;
  grid-row: 3/4;
  @media only screen and (max-width: $bp-medium) {
    grid-column: 2/10;
  }
}

.tableandbuttons {
  display: flex;
  flex-direction: column;
  gap: 1rem 0rem;
  overflow-x: auto;
}

.navigationbuttonsandsearch {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.tablecontainer {
  overflow-x: auto;
  max-width: 100%;
}

.resptable {
  border-collapse: collapse;
  background-color: #f7f7f7; /* Light gray background */
  border: 1px solid #007aff; /* Blue border */
  border-radius: 8px; /* Rounded corners */
  width: 100%;
}

.resptable th {
  background-color: #007aff; /* Blue header background */
  color: #fff; /* White header text color */
  border: 1px solid #007aff;
  padding: 10px;
  text-align: center;
}
.resptable td {
  border: 1px solid #007aff;
  padding: 10px;
  text-align: center;
}

.resptable tbody tr:hover {
  background-color: #e6e6e6; /* Light blue on hover */
}

.navigationbuttons {
  display: flex;
  flex-direction: row;
  gap: 0rem 1rem;
}

.navigationbuttons > * {
  cursor: pointer;
  width: 2.5rem;
  height: 2.5rem;
  background: #007aff;
  border: 1px solid #007aff;
  border-radius: 0.5rem;
  color: white;
  font-size: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.navigationbuttons > *:hover {
  background: #0050aa;
}

.buttonpara {
  background-color: white;
  color: black;
  font-size: 1.5rem;
}
.buttonpara:hover {
  background-color: white;
}

.go-back-button {
  background-color: #007bff; /* Blue background color */
  color: #fff; /* White text color */
  padding: 10px 15px; /* Add padding for better appearance */
  font-size: 16px; /* Set font size */
  cursor: pointer; /* Change cursor to pointer on hover */
  border: none; /* Remove border */
  border-radius: 5px; /* Add border radius for rounded corners */
}

.go-back-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

// .resptable{
//     border-spacing: 0;
//     width: 100%;
//     border-collapse: collapse;
//     background-color: #f0f7ff; /* Light blue background */
//     border: 1px solid #007aff; /* Blue border */
// }

// .resptable th {
//     background-color: #007aff; /* Blue header background */
//     color: #fff; /* White header text color */
//     border: 1px solid #007aff;
//     padding: 8px;
//     text-align: left;
// }
// .resptable td {
//     border: 1px solid #007aff;
//     padding: 8px;
//     text-align: left;
// }

// .resptable tbody tr:hover {
//     background-color: #e6f3ff; /* Light blue on hover */
//   }
