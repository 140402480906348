.sidebar-container {
    // background-color:var(--color-grey-light-2);
  // margin-right: 60px;
  display: flex;
  flex-direction: column;
  transition: all 0.5s;
height:100%;
@media only screen and (max-width: $bp-smallest) {
  // position:relative;
margin-top: 3px;

}
// border:var(--line);
margin-right: 6rem;


position:relative;
// margin-right: 6rem;
margin-top: 3px;
}


.sidebar {
  
  padding:1rem;
  border-right: var(--line);
  height:100%;
  display: flex;
  flex-direction: column;
  width: 20rem;
  gap: 1.5rem 0rem;
  // border:var(--line);
  background-color: white;
  transition: all 0.5s;
  position:absolute;
  box-shadow: 1px 1px 5px lightgray;
  z-index:10;
  // overflow: hidden;
  color: black;
  @media only screen and (max-width: $bp-smallest) {
  //   position:absolute;
  // z-index:10;
  
  }
  &_section {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &_link {
    margin-right:10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: black;
    &_icon {
      margin-right: 20px;
    }
  }
}



